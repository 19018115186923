<script setup>
import { ref, computed, watch } from 'vue'
import { useAgentStore } from '~/store/agent'
import { useAuthStore } from '~/store/auth.js'
import { trackEvent } from '~/services/tracking.service'

const agentStore = useAgentStore()

const branches = computed(() => agentStore.branchesFiltered)
const feeEarnerRef = computed(() => agentStore.feeEarnerRef)
const hasFeeEarnerBrunches = computed(() => agentStore.hasFeeEarnerBrunches(branches.value))

const isLoading = ref(false)
const isInternalUser = computed(() => useAuthStore().isInternalUser)

watch([() => agentStore.currentEstateAgentName], async () => {
  await fetchEstateAgents()
})

const fetchEstateAgents = async () => {
  isLoading.value = true
  return await agentStore.fetchEstateAgents().finally(() => (isLoading.value = false))
}
</script>

<template>
  <div class="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
    <el-select v-model="agentStore.currentBranch" :loading="isLoading" size="large" placeholder="Select" filterable>
      <el-option v-if="isInternalUser" :disabled="!hasFeeEarnerBrunches" value="belongs_to_fee_earner" label="All Fee Earner's Branches" />
      <el-option value="all" label="All Branches" />
      <el-option
        v-for="branch in branches"
        :key="branch.estate_agent_ref"
        :class="{ 'bg-gray-100': isInternalUser && feeEarnerRef !== branch.fee_earner_ref }"
        :label="
          !isInternalUser || feeEarnerRef === branch.fee_earner_ref
            ? branch.estate_agent_name
            : branch.estate_agent_name + ' (Non Fee Earner\'s)'
        "
        :value="branch?.estate_agent_ref"
        @change="
          trackEvent('BRANCH_SELECTOR', { branch: branch.estate_agent_name, type: 'change', action: 'Select an estate agent branch' })
        "
      />
    </el-select>
  </div>
</template>
